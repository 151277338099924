<script>

import Vue from 'vue';
const nprogress = require('nprogress');
const moment    = require('moment');
const axios     = require('axios');
const boxes     = require('../perimeter.json');

export default {
    name: 'app',
    components: {},
    computed: {},
    watch: {},
    created () {},
    mounted () {
        this.$nextTick(function () {
            nprogress.done(true);
            this.map = this.$refs.myMap.mapObject;
            this.map.addControl(new window.L.Control.Fullscreen());
            this.map.setView([ 45.445049788682816, 12.31792391612436 ], 11);
            var self = this;
            this.map.on('movestart', () => {
                //console.log('la mappa comincia a muoversi');
                if ( this.current_l ) {
                    this.current_l.remove();
                }
            });
            this.map.on('moveend', () => {
                //console.log('la mappa si ferma');
                this.add_current_layer( this.civ );
            });
            this.map.on('overlayadd', function(e, layer_name) {
              //console.log( e );
              var match = e.name.match( /^correnti (.+)$/ );
              if ( !match ) { return; }
              if ( self.current_l ) {
                  self.current_l.remove();
              }
              self.add_current_layer( match[1] );
            });
            this.map.on('overlayremove', function(e, layer_name) {
              console.log( e );
              var match = e.name.match( /^correnti (.+)$/ );
              if ( !match ) { return; }
              //if ( self.current_l ) {
                  self.current_l.remove();
              //}
            });
            L.tileLayer(
                'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { // mappa demo leaflet
                maxZoom: 14,
                attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
            }).addTo(this.map);

            this.box_l = L.geoJSON( boxes, {
            });


            // GESTORE DEI LAYERS
            var base_maps = {
                'perimetro'     : this.box_l,
                'correnti low'  : this.get_empty_layer(),
                'correnti mid'  : this.get_empty_layer(),
                'correnti high' : this.add_current_layer('high'),
                //'correnti low'  : this.get_empty_layer(),
                //'correnti mid'  : this.get_empty_layer(),
                //'correnti high' : this.get_empty_layer(),
            };
            L.control.layers(null, base_maps).addTo(this.map);

        });
    },
    methods: { // https://piperapi.digitalautomations.it/static/current_00035.json
       async add_current_layer( level ) {
            console.log( level );
            var urls = {
                'low'  : 'https://piperapi.digitalautomations.it/static/current_0005.json',
                'mid'  : 'https://piperapi.digitalautomations.it/static/current_00035.json',
                'high' : 'https://piperapi.digitalautomations.it/static/current_00025.json',
            };
            if ( this.civ !== level ) {
                this.civ = level;
                var url = urls[ level ];
                await axios.get( url )
                    .then( response => {
                        this.dataiv = response.data;
                    });
            }
            this.add_current_layer_static( this.dataiv );
       },
       add_current_layer_static( data ) {
            if ( this.current_l ) {
                this.current_l.remove();
            }
            this.current_l = L.velocityLayer({
                displayValues: true,
                displayOptions: {
                  //angleConvention: "CCW",
                  //velocityType: "GBR Water",
                  position: "bottomleft",
                  emptyString: "No water data"
                },
                data: data,
                maxVelocity: 0.6,
                velocityScale: 0.1 // arbitrary default 0.005
            });
            this.current_l.addTo(this.map);
       },
       get_empty_layer() {
            return L.velocityLayer({
                displayValues: true,
                displayOptions: {
                  //angleConvention: "CCW",
                  //velocityType: "GBR Water",
                  position: "bottomleft",
                  emptyString: "No water data"
                },
                data: [],
                maxVelocity: 0.6,
                velocityScale: 0.1 // arbitrary default 0.005
            });
       },
//     stops2gradient: function (stops) {
//          var gradient = {
//              vector: [
//                  ['0%', '50%'],
//                  ['100%', '50%']
//              ],
//              stops: [{
//                  'offset': '0%',
//                      'style': {
//                      'color': stops[0],
//                          'opacity': 1
//                  }
//              }, {
//                  'offset': '100%',
//                      'style': {
//                      'color': stops[1],
//                          'opacity': 1
//                  }
//              }]
//          };
//          
//          return gradient;
//      },
    },
    data () {
        return {
            map     : null,
            civ     : null, // current in vision
            dataiv  : null, // data in vision
        }
    },
}

</script>

<template>
    <div id="app">
        <div style="position: absolute; top: 13px; left: 50%; padding: 5px; z-index: 1000;" >
            <div style="position: relative; left: -50%; padding: 10px; text-align: center; background-color: white; border-radius: 15px; opacity: 0.9;">
                <a href="https://digitalautomations.it/"><img src="@/assets/logo.png" style="width: 120px;" /></a>
            </div>
        </div>
        <l-map ref="myMap" style="">
        </l-map>
    </div>
</template>

<style>
html, body, #app {
  height: 100%;
  margin: 0;
}
</style>

