//////////////////////////////

require('dotenv').config();
import Vue from 'vue';
//import { store }  from '@/store';
//import { router } from '@/helpers';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { InfoControl, ReferenceChart, ChoroplethLayer } from 'vue-choropleth'
import 'leaflet/dist/leaflet.css';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-info-control', InfoControl);
Vue.component('l-reference-chart', ReferenceChart);
Vue.component('l-choropleth-layer', ChoroplethLayer);

import 'leaflet/dist/leaflet.css';
//import 'leaflet/dist/images/marker-shadow.png';
import './plugins/bootstrap-vue';
import './plugins/nprogress';
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon.vue'
import 'leaflet-velocity/dist/leaflet-velocity.css'
import 'leaflet-velocity/dist/leaflet-velocity'

Vue.component('icon', Icon)

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import App from '@/App.vue';
Vue.config.productionTip = false;

new Vue({
    el: '#app',
    //router,
    //store,
    render: h => h(App)
});

